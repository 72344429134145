import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Headline from 'components/Headline';

const useClasses = createClasses((theme, {title, disableGuttersX, disableGutterBottom, spacingType = 'gutter', centered, gutterTop, maxWidth, marginTop}) => ({
	root: {
		...theme.mixins.typography.heading,
		...(!disableGuttersX && ({
			paddingX: theme.config.containerSpacing
		})),
		...(!disableGutterBottom && ({
			marginBottom: {
				...theme.config[`${spacingType}Spacing`],
				xs: 1.5
			}
		})),
		...(centered && {
			justifyContent: 'center'
		}),
		maxWidth: maxWidth || theme.config.containerMaxWidth,
		display: 'flex',
		flexDirection: 'row',
		columnGap: {xs: 1, md: 1.5, lg: 2},
		rowGap: {xs: .5, md: 0},
		marginTop: gutterTop ? theme.config.gutterSpacing : marginTop || {xs: 1, sm: 0},
		fontSize: {
			xs: theme.typography.pxToRem(18),
			sm: theme.typography.pxToRem(24),
			md: theme.typography.pxToRem(30)
		}
	},
	subheading: {
		...theme.mixins.typography.subheading,
		display: 'inline-flex',
		color: theme.config.palette.text.secondary,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: {
			xs: theme.typography.pxToRem(title ? 14 : 16),
			sm: theme.typography.pxToRem(18),
			md: theme.typography.pxToRem(24)
		}
	}
}), {
	name: 'PageHeadline'
});

function PageHeadline(props) {
	const {
		classes: classesProp,
		className,
		children,
		headline,
		title,
		subtitle,
		spacingType,
		disableGuttersX,
		disableGutterBottom,
		centered,
		gutterTop,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Headline
			variant="h4"
			component="h2"
			alignItems="baseline"
			flexWrap="wrap"
			width="100%"
			title={headline || title}
			subtitle={headline ? null : subtitle}
			{...rest}
			classes={{
				...props?.classes,
				root: classes.root,
				subheading: classes.subheading
			}}
		>
			{children}
		</Headline>
	);
}

PageHeadline.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	disableGutterBottom: PropTypes.bool,
	disableGuttersX: PropTypes.bool,
	centered: PropTypes.bool,
	gutterTop: PropTypes.bool
};

export default React.memo(PageHeadline);
